import React, { useContext } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthPage from "./pages/AuthPage/AuthPage";
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import MainPage from "./pages/MainPage/MainPage";
import AdvPage from "./pages/AdvPage/AdvPage";
import BrandTitlesAndDescriptionsPage from "./pages/TittlePage/BrandTitlesAndDescriptionsPage";
import TitlesAndDescriptionsPage from "./pages/TittlePage/TitlesAndDescriptionsPage";
import TitlePoland from './pages/TittlePage/TitlePoland';
import BrandTitlePoland from './pages/TittlePage/BrandTitlePoland';
import InstrPage from "./pages/InstrPage/InstrPage";
import BlogPostPage from "./components/BlogPostPage/BlogPostPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import { AuthContext } from './context/AuthContext';
import LinkPage from "./pages/LinkPage/LinkPage";
import Accounts from "./pages/Accounts/Accounts";
import AntikPage from "./pages/AntikPage/AntikPage";


const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
    const { role } = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={props =>
                allowedRoles.includes(role) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

export const useRoutes = (isLogin) => {
    const { role } = useContext(AuthContext);

    if (!isLogin) {
        return (
            <Switch>
                <Route path='/login' exact component={AuthPage} />
                <Redirect to="/login" />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route path='/' exact component={WelcomePage} />
            <ProtectedRoute path='/main' component={MainPage} allowedRoles={['Admin', 'Ppc']} />
            <Route path='/adv' component={AdvPage} />
            <Route path='/titleUkr' component={TitlesAndDescriptionsPage} />
            <Route path='/titleBrandUkr' component={BrandTitlesAndDescriptionsPage} />
            <Route path='/titlePoland' component={TitlePoland} />
            <Route path='/titleBrandPoland' component={BrandTitlePoland} />
            <ProtectedRoute path='/links' component={LinkPage} allowedRoles={['Admin', 'Ppc']} />
            <Route path='/instr' exact component={InstrPage} />
            <Route path="/blog/:id" component={BlogPostPage} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/antik" component={AntikPage} />
            <ProtectedRoute path="/landing" component={LandingPage} allowedRoles={['Admin', 'Ppc']} />
            <Redirect to="/" />
        </Switch>
    );
};

import React, { useState, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import './MainPage.scss';

const MainPage = () => {
    const [textCheck, setTextCheck] = useState('');
    const [textAdd, setTextAdd] = useState('');
    const { userId } = useContext(AuthContext);
    const [todos, setTodos] = useState([]);
    const [duplicateMessage, setDuplicateMessage] = useState('');
    const [deletingTodo, setDeletingTodo] = useState(null);

    const getTodo = useCallback(async () => {
        try {
            const response = await axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/todo', {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            setTodos(response.data);
        } catch (error) {
            console.log(error);
        }
    }, [userId]);

    const createTodo = useCallback(async () => {
        if (!textAdd) return;

        const words = textAdd.split(/\s+/);

        const newTodos = [];
        const duplicateMessages = [];

        await Promise.all(words.map(async word => {
            const lowerCaseWord = word.toLowerCase();
            const isDuplicate = todos.some(todo => todo.text.toLowerCase() === lowerCaseWord);
            if (isDuplicate) {
                duplicateMessages.push(`${word}: This domain name already exists.`);
            } else {
                try {
                    const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/todo/add', { text: word }, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    newTodos.push(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
        }));

        setTodos([...todos, ...newTodos]);
        setDuplicateMessage(duplicateMessages.join('\n'));
        setTextAdd('');
    }, [textAdd, todos]);

    const checkTodo = useCallback(async () => {
        if (!textCheck) return;

        const words = textCheck.split(/\s+/);

        const duplicateMessages = [];

        words.forEach(word => {
            const lowerCaseWord = word.toLowerCase();
            const isDuplicate = todos.some(todo => todo.text.toLowerCase() === lowerCaseWord);
            if (isDuplicate) {
                duplicateMessages.push(<span className="status-message status-message--error">{`${word}: This domain name already exists.`}</span>);
            } else {
                duplicateMessages.push(<span className="status-message status-message--success">{`${word}: This domain name is FREE`}</span>);
            }
        });

        setDuplicateMessage(<div>{duplicateMessages}</div>);
    }, [textCheck, todos]);

    const removeTodos = useCallback(async (todo) => {
        setDeletingTodo(todo);
    }, []);

    const confirmDelete = async (id) => {
        try {
            await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/todo/delete/${id}`, {
                headers: { 'Content-Type': 'application/json' }
            });
            await getTodo();
            setDeletingTodo(null);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTodo();
    }, [getTodo]);

    return (
        <div className="main-page">
            <div className="main-page__content">
                <form className="form form--check" onSubmit={e => e.preventDefault()}>
                    <div className="form__row">
                        <div className="input-field ">
                            <input
                                type="text"
                                id="textCheck"
                                name="inputCheck"
                                className="validate"
                                value={textCheck}
                                onChange={e => {
                                    setTextCheck(e.target.value);
                                    setDuplicateMessage('');
                                }}
                            />
                            <label htmlFor="inputCheck">Перевірка домену на дублювання</label>
                            {duplicateMessage && <p className="form__error-message">{duplicateMessage}</p>}
                        </div>
                        <button className="form__button" onClick={checkTodo}>
                            <i className="material-icons form__button-check">check</i>
                        </button>
                    </div>
                
                </form>
                <form className="form form--add" onSubmit={e => e.preventDefault()}>
                    <div className="form__row">
                        <div className="input-field">
                            <input
                                type="text"
                                id="textAdd"
                                name="inputAdd"
                                className="validate"
                                value={textAdd}
                                onChange={e => {
                                    setTextAdd(e.target.value);
                                    setDuplicateMessage('');
                                }}
                            />
                            <label htmlFor="inputAdd">Додати доменне імʼя</label>
                        </div>
                        <button className="form__button" onClick={createTodo}>
                            <i className="material-icons ">send</i>
                        </button>
                    </div>
                </form>

                <h3>Використані домени</h3>
                <div className="todo-list">
                    {todos.slice().reverse().map((todo, index) => (
                        <div key={todo._id}>
                            <div className="todo-list__item">
                                <div className="todo-list__textbox">
                                    <div className="todo-list__num">{todos.length - index}</div>
                                    <div className="todo-list__text">{todo.text}</div>
                                </div>
                                <div className="todo-list__buttons">
                                    <i
                                        className="material-icons todo-list__icon todo-list__icon--delete"
                                        onClick={() => removeTodos(todo)}
                                    >
                                        delete
                                    </i>
                                </div>
                            </div>
                            {deletingTodo && deletingTodo._id === todo._id && (
                                <div className="confirmation-modal">
                                    <div className="confirmation-modal__content">
                                        <p>Delete <span>{todo.text}</span>?</p>
                                        <div className="confirmation-modal__buttons">
                                            <button className="button button--confirm" onClick={() => confirmDelete(todo._id)}>Confirm</button>
                                            <button className="button button--cancel" onClick={() => setDeletingTodo(null)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MainPage;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import DropOptions from "../DropOptions/DropOptions";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import { AuthContext } from "../../context/AuthContext";
import "./Accpersonal.scss";
import moment from "moment";

const Accpersonal = ({ farmer, onResetFarmer }) => {
    // Стан для замовників
    const [customers, setCustomers] = useState([]);
    const [activeCustomer, setActiveCustomer] = useState(null);

    // Стан для замовлень
    const [orders, setOrders] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [pendingCounts, setPendingCounts] = useState({});

    // Стан для форми створення замовлення
    const [numberValue, setNumberValue] = useState("");
    const [textValue, setTextValue] = useState("");
    const [radioValue, setRadioValue] = useState("");
    const [dropdownValue1, setDropdownValue1] = useState("");
    const [dropdownValue2, setDropdownValue2] = useState("");
    const [dropdownValue3, setDropdownValue3] = useState("");
    const [dropdownValue4, setDropdownValue4] = useState("");
    const [priorityValue, setPriorityValue] = useState(1);

    // Стан для редагування замовлень
    const [isEditing, setIsEditing] = useState(null);
    const [editData, setEditData] = useState({
        number: "",
        text: "",
        radio: "",
        dropdown1: "",
        dropdown2: "",
        dropdown3: "",
        dropdown4: "",
        status: "",
        priority: 1,
    });

    // Стан для підтвердження видалення замовлення
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(null);

    // Стан для керування відкритими дропдаунами
    const [openDropdown, setOpenDropdown] = useState(null);

    // Стан для відображення списку опцій
    const [optionsUpdated, setOptionsUpdated] = useState(0);

    // Новий стан для зберігання всіх опцій фармера
    const [allOptions, setAllOptions] = useState([]);

    const { role, name } = useContext(AuthContext);
    const userRole = role || "User";
    const allowedRoles = ["Admin", "Farm"];

    const priorityLabels = {
        1: 'Дуже низький',
        2: 'Низький',
        3: 'Середній',
        4: 'Високий',
        5: 'Дуже високий',
    };

    // Завантаження замовників
    useEffect(() => {
        fetchCustomers();
    }, [farmer]);

    const fetchCustomers = async () => {
        try {
            const response = await axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/customers');
            setCustomers(response.data);
            if (response.data.length > 0) {
                setActiveCustomer(response.data[0]);
            }
        } catch (error) {
            console.error('Помилка завантаження замовників:', error);
        }
    };

    // Завантаження замовлень при зміні активного фармера або замовника
    useEffect(() => {
        if (farmer && activeCustomer) {
            fetchOrders();
        } else {
            setOrders([]);
        }
    }, [farmer, activeCustomer]);

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${farmer._id}/${activeCustomer._id}`);
            setOrders(response.data || []);
        } catch (error) {
            console.error("Помилка при завантаженні замовлень:", error);
            setOrders([]);
        }
    };

    // Завантаження всіх замовлень для підрахунку статусів
    useEffect(() => {
        fetchAllOrders();
    }, [farmer, customers, optionsUpdated]);

    const fetchAllOrders = async () => {
        if (farmer && customers.length > 0) {
            try {
                const allDataArrays = await Promise.all(
                    customers.map(async (customer) => {
                        const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${farmer._id}/${customer._id}`);
                        const data = response.data || [];
                        return { customerId: customer._id, data };
                    })
                );
                const allData = allDataArrays.flatMap((item) => item.data);
                setAllOrders(allData);

                const counts = {};
                allDataArrays.forEach((item) => {
                    const pendingCount = item.data.filter((order) => order.status === 'Pending').length;
                    counts[item.customerId] = pendingCount;
                });
                setPendingCounts(counts);
            } catch (error) {
                console.error("Помилка при завантаженні всіх замовлень:", error);
                setAllOrders([]);
                setPendingCounts({});
            }
        }
    };

    // Завантаження всіх опцій для фармера
    useEffect(() => {
        if (farmer) {
            fetchAllOptions();
        }
    }, [farmer, optionsUpdated]);

    const fetchAllOptions = async () => {
        if (farmer) {
            try {
                const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/options/all/${farmer._id}`);
                setAllOptions(response.data);
            } catch (error) {
                console.error('Помилка завантаження всіх опцій:', error);
            }
        }
    };

    // Функція для отримання тексту опції за ID
    const getOptionTextById = (id) => {
        const option = allOptions.find(opt => opt._id === id);
        return option ? option.text : 'Немає даних';
    };

    // Обчислення кількості замовлень за статусами
    const getStatusCounts = () => {
        const counts = { Pending: 0, 'In progress': 0, Done: 0 };

        allOrders.forEach((order) => {
            if (order.status === 'Pending') {
                counts.Pending += 1;
            } else if (order.status === 'In progress') {
                counts['In progress'] += 1;
            } else if (order.status === 'Done') {
                counts.Done += 1;
            }
        });

        return counts;
    };

    const statusCounts = getStatusCounts();

    const getStatusClassName = (status) => {
        switch (status) {
            case 'Pending':
                return 'status--waiting';
            case 'In progress':
                return 'status--in-progress';
            case 'Done':
                return 'status--completed';
            default:
                return '';
        }
    };

    // Функція для створення нового замовлення
    const handleSubmit = async () => {
        if (!dropdownValue1 || !dropdownValue2 || !numberValue) {
            alert("Будь ласка, виберіть опції та введіть кількість.");
            return;
        }

        try {
            const parsedNumber = Number(numberValue);
            if (isNaN(parsedNumber) || parsedNumber <= 0) {
                alert("Будь ласка, введіть дійсне число для кількості.");
                return;
            }

            // Визначаємо наступний порядковий номер
            const nextOrderNumber = allOrders.length > 0
                ? Math.max(...allOrders.map(o => o.orderNumber || 0)) + 1
                : 1;

            const newOrder = {
                orderNumber: nextOrderNumber,
                number: parsedNumber,
                text: textValue,
                radio: radioValue,
                dropdown1: dropdownValue1,
                dropdown2: dropdownValue2,
                dropdown3: dropdownValue3,
                dropdown4: dropdownValue4,
                priority: priorityValue,
                status: "Pending",
                name: name || "Unknown",
                date: moment().format("HH:mm, DD.MM.YYYY"),
            };

            // Відправка замовлення на сервер
            const response = await axios.post(`https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${farmer._id}/${activeCustomer._id}`, newOrder);

            // Оновлення локального стану
            setOrders((prevData) => [...prevData, response.data.order]);

            // Оновлення всіх замовлень
            fetchAllOrders();

            // Очищення полів вводу
            setNumberValue("");
            setTextValue("");
            setRadioValue("");
            setDropdownValue1("");
            setDropdownValue2("");
            setDropdownValue3("");
            setDropdownValue4("");
            setPriorityValue(1);

            // Оновлення DropOptions
            setOptionsUpdated(prev => prev + 1);
        } catch (error) {
            console.error("Помилка при відправці даних:", error.response ? error.response.data : error.message);
            alert(error.response?.data?.message || "Сталася помилка при збереженні даних.");
        }
    };

    // Функції для встановлення вибраних опцій
    const handleSelectDropdown1 = (optionId) => {
        setDropdownValue1(optionId);
        setOpenDropdown(null);
    };

    const handleSelectDropdown2 = (optionId) => {
        setDropdownValue2(optionId);
        setOpenDropdown(null);
    };

    const handleSelectDropdown3 = (optionId) => {
        setDropdownValue3(optionId);
        setOpenDropdown(null);
    };

    const handleSelectDropdown4 = (optionId) => {
        setDropdownValue4(optionId);
        setOpenDropdown(null);
    };

    // Функції для відкриття/закриття дропдаунів
    const handleDropdownOpen = (dropdownName) => {
        setOpenDropdown(dropdownName);
    };

    const handleDropdownClose = () => {
        setOpenDropdown(null);
    };

    // Функція для видалення замовлення
    const handleDelete = async (index) => {
        try {
            const orderToDelete = orders[index];
            const updatedOrders = orders.filter((_, i) => i !== index);
            setOrders(updatedOrders);

            // Оновлюємо дані на сервері
            await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${farmer._id}/${activeCustomer._id}`, updatedOrders);

            // Оновлюємо всі замовлення
            fetchAllOrders();

            // Оновлюємо DropOptions
            setOptionsUpdated(prev => prev + 1);
        } catch (error) {
            console.error("Помилка при видаленні замовлення:", error.response ? error.response.data : error.message);
            alert("Сталася помилка при видаленні замовлення.");
        }
    };

    // Функція для підтвердження видалення замовлення
    const handleDeleteRequest = (index) => {
        setIndexToDelete(index);
        setShowDeleteConfirm(true);
    };

    // Функція для скасування видалення замовлення
    const handleCancelDelete = () => {
        setShowDeleteConfirm(false);
        setIndexToDelete(null);
    };

    // Функція для підтвердження видалення замовлення
    const handleConfirmDelete = async () => {
        if (indexToDelete !== null) {
            await handleDelete(indexToDelete);
            setShowDeleteConfirm(false);
            setIndexToDelete(null);
        }
    };

    // Функція для редагування замовлення
    const handleEdit = (index) => {
        const dataToEdit = orders[index];
        setEditData({
            number: dataToEdit.number,
            text: dataToEdit.text,
            radio: dataToEdit.radio,
            dropdown1: dataToEdit.dropdown1,
            dropdown2: dataToEdit.dropdown2,
            dropdown3: dataToEdit.dropdown3,
            dropdown4: dataToEdit.dropdown4,
            status: dataToEdit.status,
            priority: dataToEdit.priority || 1,
        });
        setIsEditing(index);
    };

    // Функція для збереження редагування замовлення
    const handleSaveEdit = async () => {
        try {
            const updatedOrders = [...orders];
            const orderToUpdate = updatedOrders[isEditing];

            // Зберігаємо попередню кількість акаунтів
            const previousNumber = orderToUpdate.number;

            updatedOrders[isEditing] = {
                ...editData,
                previousNumber: previousNumber,
                name: orderToUpdate.name,
                date: orderToUpdate.date,
                editedBy: name || "Unknown",
                editDate: moment().format("HH:mm, DD.MM.YYYY"),
                orderNumber: orderToUpdate.orderNumber,
            };

            setOrders(updatedOrders);
            setIsEditing(null);

            // Відправляємо оновлені дані на сервер
            await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${farmer._id}/${activeCustomer._id}`, updatedOrders);

            // Оновлюємо всі замовлення
            fetchAllOrders();

            // Оновлюємо DropOptions
            setOptionsUpdated(prev => prev + 1);
        } catch (error) {
            console.error("Помилка при збереженні даних:", error.response ? error.response.data : error.message);
            alert("Сталася помилка при збереженні даних.");
        }
    };

    // Функція для зміни статусу замовлення
    const handleStatusChange = async (index, newStatus) => {
        try {
            const updatedOrders = [...orders];
            updatedOrders[index].status = newStatus;
            setOrders(updatedOrders);

            // Оновлюємо дані на сервері
            await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/farm/${farmer._id}/${activeCustomer._id}`, updatedOrders);

            // Оновлюємо всі замовлення
            fetchAllOrders();
        } catch (error) {
            console.error("Помилка при зміні статусу:", error.response ? error.response.data : error.message);
            alert("Сталася помилка при зміні статусу.");
        }
    };

    // Функція для додавання нового замовника
    const handleAddCustomer = async () => {
        const customerName = prompt('Введіть ім\'я нового замовника:');
        if (customerName && customerName.trim() !== '') {
            try {
                const newCustomer = {
                    name: customerName.trim(),
                };
                const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/customers', newCustomer);
                setCustomers([...customers, response.data]);
                setActiveCustomer(response.data);
            } catch (error) {
                console.error('Помилка додавання замовника:', error.response ? error.response.data : error.message);
                alert("Сталася помилка при додаванні замовника.");
            }
        } else {
            alert("Ім'я замовника не може бути порожнім.");
        }
    };

    // Функція для видалення замовника
    const handleDeleteCustomer = async (customerId) => {
        const confirmDelete = window.confirm("Ви впевнені, що хочете видалити цього замовника?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/customers/${customerId}`);
            const updatedCustomers = customers.filter(customer => customer._id !== customerId);
            setCustomers(updatedCustomers);

            // Якщо видалений замовник був активним, встановлюємо активним іншим
            if (activeCustomer && activeCustomer._id === customerId) {
                setActiveCustomer(updatedCustomers.length > 0 ? updatedCustomers[0] : null);
            }

            // Оновлюємо всі замовлення
            fetchAllOrders();

            // Оновлюємо DropOptions
            setOptionsUpdated(prev => prev + 1);
        } catch (error) {
            console.error('Помилка видалення замовника:', error.response ? error.response.data : error.message);
            alert("Сталася помилка при видаленні замовника.");
        }
    };

    return (
        <div className="accpersonal">
            {/* Кнопка для повернення до списку фармерів */}
            <button className="back-button" onClick={onResetFarmer}>
                <i className="material-icons">arrow_back</i>Повернутися до фармерів
            </button>

            <h2>{farmer.title}</h2>
            <p>{farmer.description}</p>

            {/* Секція для відображення кількостей */}
            <div className="accpersonal__status-counts">
                <p>Pending: <span className="status--waiting">{statusCounts.Pending}</span></p>
                <p>In progress: <span className="status--in-progress">{statusCounts['In progress']}</span></p>
                <p>Done:  <span className="status--completed">{statusCounts.Done}</span></p>
            </div>

            {/* Вибір замовника */}
            <div className="accpersonal__customers">
                {customers.map((customer) => (
                    <div key={customer._id} className="customer-item">
                        {/* Відображення кількості Pending замовлень */}
                        
                        <button
                            
                            onClick={() => setActiveCustomer(customer)}
                            className={`button ${activeCustomer && activeCustomer._id === customer._id ? 'active' : ''}`}
                        >
                            <span className="pending-count">{pendingCounts[customer._id]}</span>
                            {customer.name}
                            {/* Кнопка для видалення замовника */}
                            {allowedRoles.includes(userRole) && (
                                <button
                                    onClick={() => handleDeleteCustomer(customer._id)}
                                    className="delete-customer-button"
                                    title="Видалити замовника"
                                >
                                    <i className="material-icons">delete</i>
                                </button>
                              )}
                        </button>
             
                       
                    </div>
                ))}
                {/* Кнопка для додавання нового замовника */}
                <button onClick={handleAddCustomer} className="add-customer-button">
                    <i className="material-icons">add</i>
                </button>
            </div>

            {activeCustomer && (
                <div className="button-content">
                    {/* Форма для створення замовлення */}
                    <div className="rangeinput">
                        <p>Пріоритет:</p>
                        <input
                            type="range"
                            min="1"
                            max="5"
                            value={priorityValue}
                            onChange={(e) => setPriorityValue(Number(e.target.value))}
                        />
                        <span className={`priority-level-${priorityValue}`}>
                            {priorityLabels[priorityValue]}
                        </span>
                    </div>
                    <div>
                        <label>Count of Accounts</label>
                        <input
                            type="number"
                            value={numberValue}
                            onChange={(e) => setNumberValue(e.target.value)}
                            min="1"
                        />
                    </div>

                    <div>
                        <label>Description</label>
                        <input
                            type="text"
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                        />
                    </div>

                    <div className="accpersonal__form-checkboxes">
                        <div>
                            <label htmlFor="option1">
                                <input
                                    type="radio"
                                    name="landingType"
                                    id="option1"
                                    value="Прогрітий"
                                    checked={radioValue === "Прогрітий"}
                                    onChange={(e) => setRadioValue(e.target.value)}
                                />
                                <span>Прогрітий</span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="option2">
                                <input
                                    type="radio"
                                    name="landingType"
                                    id="option2"
                                    value="Без прогріву"
                                    checked={radioValue === "Без прогріву"}
                                    onChange={(e) => setRadioValue(e.target.value)}
                                />
                                <span>Без прогріву</span>
                            </label>
                        </div>
                    </div>

                    <div className="accpersonal__dropdowns">
                        <DropOptions
                            label="Гео"
                            listType="geo"
                            placeholder="Виберіть Гео"
                            onSelect={handleSelectDropdown1}
                            refresh={optionsUpdated}
                            showValue={false}
                            isOpen={openDropdown === "geo"}
                            onOpen={() => handleDropdownOpen("geo")}
                            onClose={handleDropdownClose}
                            farmerId={farmer._id} // Передаємо farmerId
                        />

                        <DropOptions
                            label="Картки"
                            listType="sim"
                            placeholder="Виберіть SIM-карту"
                            onSelect={handleSelectDropdown2}
                            refresh={optionsUpdated}
                            isOpen={openDropdown === "sim"}
                            onOpen={() => handleDropdownOpen("sim")}
                            onClose={handleDropdownClose}
                            farmerId={farmer._id} // Передаємо farmerId
                        />

                        <DropOptions
                            label="Проксі"
                            listType="additional1"
                            placeholder="Виберіть проксі"
                            onSelect={handleSelectDropdown3}
                            refresh={optionsUpdated}
                            isOpen={openDropdown === "additional1"}
                            onOpen={() => handleDropdownOpen("additional1")}
                            onClose={handleDropdownClose}
                            showValue={false}
                            farmerId={farmer._id} // Передаємо farmerId
                        />

                        <DropOptions
                            label="Платформа"
                            listType="additional2"
                            placeholder="Виберіть платформу"
                            onSelect={handleSelectDropdown4}
                            refresh={optionsUpdated}
                            isOpen={openDropdown === "additional2"}
                            onOpen={() => handleDropdownOpen("additional2")}
                            onClose={handleDropdownClose}
                            showValue={false}
                            farmerId={farmer._id} // Передаємо farmerId
                        />
                    </div>

                    <button className="accpersonal__button-order" onClick={handleSubmit}>
                        Замовити
                    </button>

                    {/* Відображення замовлень */}
                    <div className="accpersonal__orders">
                        <h3 className="accpersonal__order-title">
                            Замовлення <span>{activeCustomer.name && `- ${activeCustomer.name}`}</span>
                        </h3>
                        <ul className="accpersonal__order-list">
                            {orders && orders.length > 0 ? (
                                orders.map((data, index) => (
                                    <li key={data.orderNumber || index}>
                                        {isEditing === index ? (
                                            <div className="data-text">
                                                <div>
                                                    <label>Кількість:</label>
                                                    <input
                                                        type="number"
                                                        value={editData.number}
                                                        onChange={(e) =>
                                                            setEditData({
                                                                ...editData,
                                                                number: e.target.value,
                                                            })
                                                        }
                                                        min="1"
                                                    />
                                                </div>
                                                <div className="input-field">
                                                    <p>Пріоритет:</p>
                                                    <p className="range-field">
                                                        <input
                                                            type="range"
                                                            min="1"
                                                            max="5"
                                                            value={editData.priority}
                                                            onChange={(e) => {
                                                                const newPriority = Number(e.target.value);
                                                                setEditData({ ...editData, priority: newPriority });
                                                            }}
                                                        />
                                                    </p>
                                                    <span className={`priority-level-${editData.priority}`}>
                                                        {priorityLabels[editData.priority]}
                                                    </span>
                                                </div>
                                                <div>
                                                    <label>Опис:</label>
                                                    <input
                                                        type="text"
                                                        value={editData.text}
                                                        onChange={(e) =>
                                                            setEditData({ ...editData, text: e.target.value })
                                                        }
                                                        placeholder="Введіть текст"
                                                    />
                                                </div>

                                                <div className="accpersonal__form-checkboxes">
                                                    <div>
                                                        <label htmlFor={`edit-option1-${index}`}>
                                                            <input
                                                                type="radio"
                                                                name={`edit-radio-group-${index}`}
                                                                id={`edit-option1-${index}`}
                                                                value="Прогрітий"
                                                                checked={editData.radio === "Прогрітий"}
                                                                onChange={(e) =>
                                                                    setEditData({
                                                                        ...editData,
                                                                        radio: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <span>Прогрітий</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label htmlFor={`edit-option2-${index}`}>
                                                            <input
                                                                type="radio"
                                                                name={`edit-radio-group-${index}`}
                                                                id={`edit-option2-${index}`}
                                                                value="Без прогріву"
                                                                checked={editData.radio === "Без прогріву"}
                                                                onChange={(e) =>
                                                                    setEditData({
                                                                        ...editData,
                                                                        radio: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <span>Без прогріву</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                {/* Дропдауни для редагування */}
                                                <div className="accpersonal__dropdowns">
                                                    <DropOptions
                                                        label="Гео"
                                                        listType="geo"
                                                        placeholder="Виберіть Гео"
                                                        onSelect={(optionId) => {
                                                            setEditData({
                                                                ...editData,
                                                                dropdown1: optionId,
                                                            });
                                                            setOpenDropdown(null);
                                                        }}
                                                        refresh={optionsUpdated}
                                                        isOpen={openDropdown === `edit-geo-${index}`}
                                                        onOpen={() => handleDropdownOpen(`edit-geo-${index}`)}
                                                        onClose={handleDropdownClose}
                                                        farmerId={farmer._id} // Передаємо farmerId
                                                    />
                                                    <DropOptions
                                                        label="Картки"
                                                        listType="sim"
                                                        placeholder="Виберіть SIM-карту"
                                                        onSelect={(optionId) => {
                                                            setEditData({
                                                                ...editData,
                                                                dropdown2: optionId,
                                                            });
                                                            setOpenDropdown(null);
                                                        }}
                                                        refresh={optionsUpdated}
                                                        isOpen={openDropdown === `edit-sim-${index}`}
                                                        onOpen={() => handleDropdownOpen(`edit-sim-${index}`)}
                                                        onClose={handleDropdownClose}
                                                        farmerId={farmer._id} // Передаємо farmerId
                                                    />
                                                    <DropOptions
                                                        label="Проксі"
                                                        listType="additional1"
                                                        placeholder="Виберіть проксі"
                                                        onSelect={(optionId) => {
                                                            setEditData({
                                                                ...editData,
                                                                dropdown3: optionId,
                                                            });
                                                            setOpenDropdown(null);
                                                        }}
                                                        refresh={optionsUpdated}
                                                        isOpen={openDropdown === `edit-additional1-${index}`}
                                                        onOpen={() => handleDropdownOpen(`edit-additional1-${index}`)}
                                                        onClose={handleDropdownClose}
                                                        showValue={false}
                                                        farmerId={farmer._id} // Передаємо farmerId
                                                    />
                                                    <DropOptions
                                                        label="Платформа"
                                                        listType="additional2"
                                                        placeholder="Виберіть платформу"
                                                        onSelect={(optionId) => {
                                                            setEditData({
                                                                ...editData,
                                                                dropdown4: optionId,
                                                            });
                                                            setOpenDropdown(null);
                                                        }}
                                                        refresh={optionsUpdated}
                                                        isOpen={openDropdown === `edit-additional2-${index}`}
                                                        onOpen={() => handleDropdownOpen(`edit-additional2-${index}`)}
                                                        onClose={handleDropdownClose}
                                                        showValue={false}
                                                        farmerId={farmer._id} // Передаємо farmerId
                                                    />
                                                </div>

                                                <button
                                                    className="display-data__icon-button-save"
                                                    onClick={handleSaveEdit}
                                                >
                                                    <i className="material-icons">save</i>
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="display-data">
                                                <div className="data-text">
                                                    <div>
                                                        <p>№ Замовлення:</p>
                                                        <span>{data.orderNumber || "Немає даних"}</span>
                                                    </div>
                                                    <div>
                                                        <p>Пріоритет:</p>
                                                        <h6 className={`priority-level-${data.priority}`}>
                                                            {priorityLabels[data.priority] || 'Немає даних'}
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <p>Кількість:</p>
                                                        {data.previousNumber ? (
                                                            <span><h5>{data.previousNumber}</h5> ➔ {data.number}</span>
                                                        ) : (
                                                            <span>{data.number || "Немає даних"}</span>
                                                        )}
                                                    </div>
                                                    <section className="data-text-textarea">
                                                        <p>Опис:</p>
                                                        <textarea readOnly>
                                                            {data.text || "Немає даних"}
                                                        </textarea>
                                                    </section>
                                                    <div>
                                                        <p>Прогрів:</p>
                                                        <span>{data.radio || "Немає даних"}</span>
                                                    </div>
                                                    <div>
                                                        <p>Гео:</p>
                                                        <span>{getOptionTextById(data.dropdown1)}</span>
                                                    </div>
                                                    <div>
                                                        <p>SIM-карта:</p>
                                                        <span>{getOptionTextById(data.dropdown2)}</span>
                                                    </div>
                                                    <div>
                                                        <p>Проксі:</p>
                                                        <span>{getOptionTextById(data.dropdown3)}</span>
                                                    </div>
                                                    <div>
                                                        <p>Платформа:</p>
                                                        <span>{getOptionTextById(data.dropdown4)}</span>
                                                    </div>

                                                    {/* Додати дату та ім'я */}
                                                    <div>
                                                        <p>Ім'я замовника:</p>
                                                        <span>{data.name || "Немає даних"}</span>
                                                    </div>
                                                    <div>
                                                        <p>Дата додавання:</p>
                                                        <span>
                                                            {data.date}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <p>Останній редактор:</p>
                                                        <span>{data.editedBy || "Немає даних"}</span>
                                                    </div>
                                                    <div>
                                                        <p>Дата редагування:</p>
                                                        <span>{data.editDate || "Немає даних"}</span>
                                                    </div>
                                                    <ul className="data-text__status-box">
                                                        <div className="data-text__status-box-color">
                                                            <p>Статус:</p>
                                                            <h4 className={getStatusClassName(data.status)}>
                                                                {data.status || "Немає даних"}
                                                            </h4>
                                                        </div>

                                                        {(allowedRoles.includes(userRole)) && (
                                                            <StatusDropdown
                                                                options={["Pending", "In progress", "Done"]}
                                                                selected={data.status}
                                                                onSelect={(value) =>
                                                                    handleStatusChange(index, value)
                                                                }
                                                            />
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="display-data__icon-button">
                                                    <button
                                                        className="display-data__icon-button-edit"
                                                        onClick={() => handleEdit(index)}
                                                    >
                                                        <i className="material-icons">edit</i>
                                                    </button>
                                                    <button
                                                        className="display-data__icon-button-delete"
                                                        onClick={() => handleDeleteRequest(index)}
                                                    >
                                                        <i className="material-icons">delete</i>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                ))
                            ) : (
                                <li>Дані відсутні</li>
                            )}
                        </ul>
                        {showDeleteConfirm && (
                            <div className="delete-confirmation">
                                <p>Ви впевнені, що хочете видалити це замовлення?</p>
                                <button onClick={handleConfirmDelete} className="confirm-button">
                                    Так
                                </button>
                                <button onClick={handleCancelDelete} className="cancel-button">
                                    Ні
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Accpersonal;

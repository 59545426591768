// InstrPage.js

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './InstrPage.scss';
import { AuthContext } from '../../context/AuthContext';

const InstrPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [newPost, setNewPost] = useState({ title: '', image: null, category: '' });
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);
  const [editingPost, setEditingPost] = useState(null);

  const { role, name } = useContext(AuthContext);

  useEffect(() => {
    axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts')
      .then(response => setBlogPosts(response.data))
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPost({ ...newPost, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewPost({ ...newPost, image: file });
  };

  const handleCreatePost = async () => {
    const formData = new FormData();
    formData.append('title', newPost.title);
    formData.append('category', newPost.category);
    formData.append('authorName', name);
    formData.append('image', newPost.image);

    try {
      const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setBlogPosts(prev => [...prev, response.data]);
      setNewPost({ title: '', image: null, category: '' });
    } catch (error) {
      console.error('Error creating blog post:', error);
    }
  };

  const handleDeletePost = (id) => {
    axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts/${id}`)
      .then(() => {
        setBlogPosts(prev => prev.filter(post => post._id !== id));
        setConfirmDelete(null);
      })
      .catch(error => console.error('Error deleting blog post:', error));
  };

  const startEditing = (post) => {
    setEditingPost({ ...post, image: null });
  };

  const cancelEditing = () => {
    setEditingPost(null);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingPost({ ...editingPost, [name]: value });
  };

  const handleEditFileChange = (e) => {
    const file = e.target.files[0];
    setEditingPost({ ...editingPost, image: file });
  };

  const saveEditedPost = async () => {
    const formData = new FormData();
    formData.append('title', editingPost.title);
    if (editingPost.image) {
      formData.append('image', editingPost.image);
    }

    try {
      const response = await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts/${editingPost._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setBlogPosts(prev => prev.map(post => post._id === editingPost._id ? response.data : post));
      setEditingPost(null);
    } catch (error) {
      console.error('Error updating blog post:', error);
    }
  };

  const categorizedPosts = {
    Lendings: blogPosts.filter(post => post.category === 'Lendings'),
    Farm: blogPosts.filter(post => post.category === 'Farm'),
    'Google Ads': blogPosts.filter(post => post.category === 'Google Ads')
  };

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  return (
    <div className="instr" id="blured">
      <h1>Create Topic</h1>
      <div className='input-blog'>
        <input
          type="text"
          name="title"
          placeholder="Title"
          value={newPost.title}
          onChange={handleInputChange}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className='blog-post-img-downloader'
        />
        <select
          name="category"
          value={newPost.category}
          onChange={handleInputChange}
          className='category-select'
        >
          <option value="">Select Category</option>
          <option value="Lendings">Lendings</option>
          <option value="Farm">Farm</option>
          <option value="Google Ads">Google Ads</option>
        </select>
        <button onClick={handleCreatePost} className='title-reg-button'>Create Topic</button>
      </div>

      <div className="blog-columns">
        {(role === 'Admin' || role === 'Ppc' || role === 'Farm') && (
          <>
            {/* Категорія Lendings */}
            <div className="blog-column">
              <div onClick={() => toggleCategory('Lendings')} className='category-choice'>
                <p>Lendings</p>
                <p className='plus'>{openCategory === 'Lendings' ? '-' : '+'}</p>
              </div>
              <div className={`blog-row ${openCategory === 'Lendings' ? 'open' : ''}`}>
                {categorizedPosts.Lendings && categorizedPosts.Lendings.length > 0 ? (
                  categorizedPosts.Lendings.map((post, index) => (
                    <div key={index} className={`blog-post ${openCategory === 'Lendings' ? 'open' : ''}`}>
                      {editingPost && editingPost._id === post._id ? (
                        // Форма редагування
                        <div className='edit-blog-post'>
                          <input
                            type="text"
                            name="title"
                            value={editingPost.title}
                            onChange={handleEditInputChange}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleEditFileChange}
                          />
                          <div>
                            <button onClick={saveEditedPost}><i className="material-icons">save</i></button>
                            <button onClick={cancelEditing}><i className="material-icons">close</i></button>
                          </div>
                        </div>
                      ) : (
                        // Відображення поста
                        <>
                          <Link to={`/blog/${post._id}`}>
                            <img src={`data:image/jpeg;base64,${post.imageBase64}`} alt={post.title} />
                            <div className='blog-post-text'>
                              <span className='create-info'>Author: {post.authorName}</span>
                              <span className='create-info'>Created at: {new Date(post.createdAt).toLocaleString()}</span>
                              <h4>{post.title}</h4>
                            </div>
                          </Link>
                        
                          {confirmDelete === post._id ? (
                            <div className="confirm-delete-blog">
                              <p>Are you sure you want to delete this post?</p>
                              <div>
                                <button onClick={() => handleDeletePost(post._id)} className='confirm-button'>Yes</button>
                                <button onClick={() => setConfirmDelete(null)} className='cancel-button'>No</button>
                              </div>
                            </div>
                          ) : (
                            <div className='buttons-bost-delEdit'>
                              <button onClick={() => setConfirmDelete(post._id)} className='delete-blog-button'>
                                <i className="material-icons">delete</i>
                              </button>
                              <button onClick={() => startEditing(post)} className='edit-button-blog'>
                                <i className="material-icons">edit</i>
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No posts available</p>
                )}
              </div>
            </div>

            {/* Категорія Google Ads */}
            <div className="blog-column">
              <div onClick={() => toggleCategory('Google Ads')} className='category-choice'>
                <p>Google Ads</p>
                <p className='plus'>{openCategory === 'Google Ads' ? '-' : '+'}</p>
              </div>
              <div className={`blog-row ${openCategory === 'Google Ads' ? 'open' : ''}`}>
                {categorizedPosts['Google Ads'] && categorizedPosts['Google Ads'].length > 0 ? (
                  categorizedPosts['Google Ads'].map((post, index) => (
                    <div key={index} className={`blog-post ${openCategory === 'Google Ads' ? 'open' : ''}`}>
                      {editingPost && editingPost._id === post._id ? (
                        // Форма редагування
                        <div className='edit-blog-post'>
                          <input
                            type="text"
                            name="title"
                            value={editingPost.title}
                            onChange={handleEditInputChange}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleEditFileChange}
       
                          />
                          <div>
                            <button onClick={saveEditedPost}><i className="material-icons">save</i></button>
                            <button onClick={cancelEditing}><i className="material-icons">close</i></button>
                          </div>
                        </div>
                      ) : (
                        // Відображення поста
                        <>
                          <Link to={`/blog/${post._id}`}>
                            <img src={`data:image/jpeg;base64,${post.imageBase64}`} alt={post.title} />
                            <div className='blog-post-text'>
                              <span className='create-info'>Author: {post.authorName}</span>
                              <span className='create-info'>Created at: {new Date(post.createdAt).toLocaleString()}</span>
                              <h4>{post.title}</h4>
                            </div>
                          </Link>
                          {confirmDelete === post._id ? (
                            <div className="confirm-delete-blog">
                              <p>Are you sure you want to delete this post?</p>
                              <div>
                                <button onClick={() => handleDeletePost(post._id)} className='confirm-button'>Yes</button>
                                <button onClick={() => setConfirmDelete(null)} className='cancel-button'>No</button>
                              </div>
                            </div>
                          ) : (
                            <div className='buttons-bost-delEdit'>
                              <button onClick={() => setConfirmDelete(post._id)} className='delete-blog-button'>
                                <i className="material-icons">delete</i>
                              </button>
                              <button onClick={() => startEditing(post)} className='edit-button-blog'>
                                <i className="material-icons">edit</i>
                              </button>
                          </div>
                          )}
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No posts available</p>
                )}
              </div>
            </div>
          </>
        )}

        {(role === 'Admin' || role === 'Ppc' || role === 'Farm') && (
          // Категорія Farm
          <div className="blog-column">
            <div onClick={() => toggleCategory('Farm')} className='category-choice'>
              <p>Farm</p>
              <p className='plus'>{openCategory === 'Farm' ? '-' : '+'}</p>
            </div>
            <div className={`blog-row ${openCategory === 'Farm' ? 'open' : ''}`}>
              {categorizedPosts.Farm && categorizedPosts.Farm.length > 0 ? (
                categorizedPosts.Farm.map((post, index) => (
                  <div key={index} className={`blog-post ${openCategory === 'Farm' ? 'open' : ''}`}>
                    {editingPost && editingPost._id === post._id ? (
                      // Форма редагування
                      <div className='edit-blog-post'>
                          <input
                            type="text"
                            name="title"
                            value={editingPost.title}
                            onChange={handleEditInputChange}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleEditFileChange}
                          />
                          <div>
                            <button onClick={saveEditedPost}><i className="material-icons">save</i></button>
                            <button onClick={cancelEditing}><i className="material-icons">close</i></button>
                          </div>
                      </div>
                    ) : (
                      // Відображення поста
                      <>
                        <Link to={`/blog/${post._id}`}>
                          <img src={`data:image/jpeg;base64,${post.imageBase64}`} alt={post.title} />
                          <div className='blog-post-text'>
                            <span className='create-info'>Author: {post.authorName}</span>
                            <span className='create-info'>Created at: {new Date(post.createdAt).toLocaleString()}</span>
                            <h4>{post.title}</h4>
                          </div>
                        </Link>
                        
                        {confirmDelete === post._id ? (
                          <div className="confirm-delete-blog">
                            <p>Are you sure you want to delete this post?</p>
                            <div>
                              <button onClick={() => handleDeletePost(post._id)} className='confirm-button'>Yes</button>
                              <button onClick={() => setConfirmDelete(null)} className='cancel-button'>No</button>
                            </div>
                          </div>
                        ) : (
                          <div className='buttons-bost-delEdit'>
                            <button onClick={() => setConfirmDelete(post._id)} className='delete-blog-button'>
                              <i className="material-icons">delete</i>
                            </button>
                            <button onClick={() => startEditing(post)} className='edit-button-blog'>
                              <i className="material-icons">edit</i>
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p>No posts available</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InstrPage;

import React, { useState, useEffect } from 'react';
import './LinkPage.scss';
import axios from 'axios';

const LinkPage = () => {
    const [todoLists, setTodoLists] = useState([]);
    const [newListTitle, setNewListTitle] = useState('');

    useEffect(() => {
        fetchTodoLists();
    }, []);

    const fetchTodoLists = async () => {
        try {
            const response = await axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/todo-list');
            setTodoLists(response.data);
        } catch (err) {
            console.error('Error fetching todo lists', err);
        }
    };

    const handleAddList = async () => {
        if (newListTitle.trim() === '') return;

        try {
            const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/todo-list', {
                title: newListTitle,
                items: [{ title: '', description: '', isEditing: false }]
            });
            setTodoLists([...todoLists, response.data]);
            setNewListTitle('');
        } catch (err) {
            console.error('Error creating todo list', err);
        }
    };

    const handleAddItem = async (listId) => {
        try {
            const response = await axios.post(`https://spptesti-2e99c730e09e.herokuapp.com/api/todo-list/${listId}/item`, {
                title: 'New item title',
                description: 'New item description',
                isEditing: false,
            });
            setTodoLists(todoLists.map(list => 
                list._id === listId ? response.data : list
            ));
        } catch (err) {
            console.error('Error adding item to todo list', err);
        }
    };

    const handleUpdateItem = async (listId, itemId, field, value) => {
        const updatedLists = todoLists.map((list) => {
            if (list._id === listId) {
                const updatedItems = list.items.map((item) =>
                    item._id === itemId ? { ...item, [field]: value } : item
                );
                return { ...list, items: updatedItems };
            }
            return list;
        });
        setTodoLists(updatedLists);
    
        try {
            const itemToUpdate = updatedLists.find(list => list._id === listId).items.find(item => item._id === itemId);
            await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/todo-list/${listId}/item/${itemId}`, {
                title: itemToUpdate.title,
                description: itemToUpdate.description
            });
        } catch (err) {
            console.error('Error updating todo item', err);
        }
    };

    const handleDeleteItem = async (listId, itemId) => {
        try {
            const response = await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/todo-list/${listId}/item/${itemId}`);
            setTodoLists(todoLists.map(list => 
                list._id === listId ? response.data : list
            ));
        } catch (err) {
            console.error('Error deleting todo item', err);
        }
    };

    const toggleEditItem = async (listId, itemId) => {
        const updatedLists = todoLists.map((list) => {
            if (list._id === listId) {
                const updatedItems = list.items.map((item) =>
                    item._id === itemId ? { ...item, isEditing: !item.isEditing } : item
                );
                return { ...list, items: updatedItems };
            }
            return list;
        });
        setTodoLists(updatedLists);
    
        if (!updatedLists.find(list => list._id === listId).items.find(item => item._id === itemId).isEditing) {
            // Зберегти зміни, тільки якщо ми виходимо з режиму редагування
            const itemToUpdate = updatedLists.find(list => list._id === listId).items.find(item => item._id === itemId);
            try {
                await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/todo-list/${listId}/item/${itemId}`, {
                    title: itemToUpdate.title,
                    description: itemToUpdate.description
                });
            } catch (err) {
                console.error('Error updating todo item', err);
            }
        }
    };

    const handleCopyDescription = (description) => {
        navigator.clipboard.writeText(description).then(() => {

        }).catch((err) => {
            console.error('Could not copy text: ', err);
        });
    };

    const handleDeleteList = async (listId) => {
        try {
            await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/todo-list/${listId}`);
            setTodoLists(todoLists.filter(list => list._id !== listId));
        } catch (err) {
            console.error('Error deleting todo list', err);
        }
    };

    return (
        <div className="todo__container">
            <div className="link__row">
                <div className="input-field ">
                    <input
                        type="text"
                        value={newListTitle}
                        onChange={(e) => setNewListTitle(e.target.value)}
                        id="textCheck"
                        name="inputCheck"
                        className="validate"
                    />
                    <label htmlFor="inputCheck">Додати новий track-link список</label>
                </div>
                <button className="link__button" onClick={handleAddList}>
                    <i className="material-icons">send</i>
                </button>
            </div>
            {todoLists.map((list) => (
                <div key={list._id} className="todo__list">
                    <div className="todo__list-header">
                        <h2 className="todo__list-title">{list.title}</h2>
                        {list.items.some(item => item.isEditing) && (
                            <button onClick={() => handleDeleteList(list._id)} className="todo__button todo__delete-list-button">Delete List</button>
                        )}
                    </div>
                    <ul className="todo__items">
                        {list.items.map((item) => (
                            <li key={item._id} className="todo__item">
                                <input
                                    type="text"
                                    value={item.title}
                                    onChange={(e) =>
                                        handleUpdateItem(list._id, item._id, 'title', e.target.value)
                                    }
                                    placeholder="Item title"
                                    className="todo__item-title"
                                    disabled={!item.isEditing}
                                />
                                <input
                                    type="text"
                                    value={item.description}
                                    onChange={(e) =>
                                        handleUpdateItem(list._id, item._id, 'description', e.target.value)
                                    }
                                    placeholder="Item description"
                                    className="todo__item-description"
                                    disabled={!item.isEditing}
                                />
                                <div className='buttons'>
                                    {item.isEditing && (
                                        <>
                                           
                                            <button onClick={() => handleDeleteItem(list._id, item._id)} className="todo__button todo__edit-button">
                                                <i className="material-icons">delete</i>
                                            </button>
                                            <button onClick={() => toggleEditItem(list._id, item._id)} className="todo__button todo__edit-button">
                                                <i className="material-icons">save</i>
                                            </button>
                                        </>
                                    )}
                                    {!item.isEditing && (
                                        <>
                                            <button onClick={() => handleCopyDescription(item.description)} className="todo__button todo__edit-button">
                                                <i className="material-icons">content_copy</i>
                                            </button>
                                            <button onClick={() => toggleEditItem(list._id, item._id)} className="todo__button todo__edit-button">
                                                <i className="material-icons">edit</i>
                                            </button>
                                        </>
                                      
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                    {list.items.some(item => item.isEditing) && (
                        <button onClick={() => handleAddItem(list._id)} className="todo__button todo__edit-button"> 
                            <i className="material-icons">add</i>
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
}

export default LinkPage;

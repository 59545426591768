// src/components/StatusDropdown/StatusDropdown.jsx
import React, { useState, useRef, useEffect } from 'react';
import './StatusDropdown.scss';

const StatusDropdown = ({ options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Закриття випадаючого списку при кліку поза ним
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="status-dropdown" ref={dropdownRef}>
      <div
        className="status-dropdown__header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selected || 'Виберіть статус'}</span>
        <i className="material-icons">
          {isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        </i>
      </div>
      {isOpen && (
        <ul className="status-dropdown__list">
          {options.map((option, index) => (
            <li
              key={index}
              className="status-dropdown__item"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StatusDropdown;

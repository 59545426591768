// src/pages/Accounts/Accounts.jsx

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './Accounts.scss';
import Accpersonal from '../../components/Accpersonal/Accpersonal';
import { AuthContext } from "../../context/AuthContext";
const Accounts = () => {
    const [farmers, setFarmers] = useState([]);
    const [selectedFarmer, setSelectedFarmer] = useState(null);
    const [isAddingFarmer, setIsAddingFarmer] = useState(false);
    const [newFarmerTitle, setNewFarmerTitle] = useState('');
    const [newFarmerDescription, setNewFarmerDescription] = useState('');
    const [isDeletingFarmer, setIsDeletingFarmer] = useState(false);
    const [farmerToDelete, setFarmerToDelete] = useState(null);
    const { role, name } = useContext(AuthContext);
    const userRole = role || "User";
    const allowedRoles = ["Admin"];
    // Завантаження фармерів з бекенду при завантаженні компонента
    useEffect(() => {
        fetchFarmers();
    }, []);

    const fetchFarmers = async () => {
        try {
            const response = await axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/farmers');
            setFarmers(response.data);
        } catch (error) {
            console.error('Помилка завантаження фармерів:', error);
        }
    };

    const handleFarmerClick = (farmer) => {
        setSelectedFarmer(farmer);
    };

    const handleResetFarmer = () => {
        setSelectedFarmer(null);
    };

    const handleAddFarmer = () => {
        setIsAddingFarmer(true);
    };

    const handleSaveFarmer = async () => {
        if (!newFarmerTitle.trim()) {
            alert('Будь ласка, введіть назву фармера.');
            return;
        }

        try {
            const newFarmer = {
                title: newFarmerTitle,
                description: newFarmerDescription,
                listTypeGeo: 'list1', // Ви можете зробити ці значення динамічними або вибирати їх користувачем
                listTypeSim: 'list2',
                listTypeAdditional1: 'list5',
                listTypeAdditional2: 'list6',
            };
            const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/farmers', newFarmer);
            setFarmers([...farmers, response.data]);
            setNewFarmerTitle('');
            setNewFarmerDescription('');
            setIsAddingFarmer(false);
        } catch (error) {
            console.error('Помилка додавання фармера:', error);
            alert('Сталася помилка при додаванні фармера.');
        }
    };

    const handleCancelAddFarmer = () => {
        setIsAddingFarmer(false);
        setNewFarmerTitle('');
        setNewFarmerDescription('');
    };

    // Функції для видалення фармерa
    const handleDeleteFarmerRequest = (farmer) => {
        setFarmerToDelete(farmer);
        setIsDeletingFarmer(true);
    };

    const handleConfirmDeleteFarmer = async () => {
        if (!farmerToDelete) return;

        try {
            await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/farmers/${farmerToDelete._id}`);
            setFarmers(farmers.filter(farmer => farmer._id !== farmerToDelete._id));

            // Якщо видалений фармер був активним, скидаємо вибір
            if (selectedFarmer && selectedFarmer._id === farmerToDelete._id) {
                setSelectedFarmer(null);
            }

            setFarmerToDelete(null);
            setIsDeletingFarmer(false);
        } catch (error) {
            console.error('Помилка видалення фармера:', error);
            alert('Сталася помилка при видаленні фармера.');
            setFarmerToDelete(null);
            setIsDeletingFarmer(false);
        }
    };

    const handleCancelDeleteFarmer = () => {
        setFarmerToDelete(null);
        setIsDeletingFarmer(false);
    };

    return (
        <div className='accounts'>
            {!selectedFarmer ? (
                <div className="accounts__wrapper">
                    <h2 className='farmer-title'>Вибір фармера</h2>
                    <div className="accounts__farmers-list">
                        {farmers.map((farmer) => (
                            <div key={farmer._id} className="accounts__farmer-card shine-button" onClick={() => handleFarmerClick(farmer)}>
                                <h3 >{farmer.title}</h3>
                                {/* Кнопка для видалення фармерa */}
                                {allowedRoles.includes(userRole) && (
                                <button
                                    className="delete-farmer-button"
                                    onClick={() => handleDeleteFarmerRequest(farmer)}
                                    title="Видалити фармера"
                                >
                                    <i className="material-icons">delete</i>
                                </button>
                                )}
                            </div>
                        ))}
                        {/* Карточка для додавання нового фармера */}
                        {isAddingFarmer && allowedRoles.includes(userRole) ? (
                            
                            <div className="accounts__farmer-card add-farmer-card">
                                <input
                                    type="text"
                                    placeholder="Назва фармера"
                                    value={newFarmerTitle}
                                    onChange={(e) => setNewFarmerTitle(e.target.value)}
                                />
                                <div className="accounts__farmer-card-buttons">
                                    <button onClick={handleSaveFarmer}><i className="material-icons farmer-card-buttons-icon">save</i></button>
                                    <button onClick={handleCancelAddFarmer}><i className="material-icons farmer-card-buttons-icon">close</i></button>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="accounts__farmer-card add-farmer-card shine-button"
                                onClick={handleAddFarmer}
                            >
                                <i className="material-icons">add</i>
                                <span>Додати фармера</span>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <Accpersonal 
                    farmer={selectedFarmer} 
                    onResetFarmer={handleResetFarmer}  
                />
            )}

            {/* Модальне вікно для підтвердження видалення фармерa */}
            {isDeletingFarmer && farmerToDelete && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Підтвердження видалення</h3>
                        <div className="modal-buttons">
                            <button onClick={handleConfirmDeleteFarmer} className="confirm-button">Так</button>
                            <button onClick={handleCancelDeleteFarmer} className="cancel-button">Ні</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Accounts;

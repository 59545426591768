// components/DropOptions/DropOptions.jsx

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './DropOptions.scss';
import { AuthContext } from '../../context/AuthContext';

const DropOptions = ({
  label,
  listType,
  onSelect,
  placeholder = 'Виберіть опцію',
  refresh,
  showValue = true,
  isOpen,
  onOpen,
  onClose,
  farmerId, // Додано farmerId
}) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState('');
  const [isAddingOption, setIsAddingOption] = useState(false);
  const [newOptionText, setNewOptionText] = useState('');
  const [newOptionValue, setNewOptionValue] = useState('');
  const [isEditingOptionIndex, setIsEditingOptionIndex] = useState(null);
  const [editOptionText, setEditOptionText] = useState('');
  const [editOptionValue, setEditOptionValue] = useState('');
  const { role } = useContext(AuthContext);
  const userRole = role || 'User';

  useEffect(() => {
    fetchOptions();
  }, [listType, refresh, farmerId]); // Додано farmerId в залежності

  const fetchOptions = async () => {
    if (!farmerId) {
      console.error('farmerId is required to fetch options');
      return;
    }
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/options/${farmerId}/${listType}`);
      setOptions(response.data);
      console.log(`Fetched ${response.data.length} options for listType: ${listType}`);
    } catch (error) {
      console.error('Помилка завантаження опцій:', error.response ? error.response.data : error.message);
    }
  };

  const handleOptionClick = (option) => {
    setSelected(option.text);
    if (onSelect) {
      onSelect(option._id);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleToggle = () => {
    if (isOpen) {
      if (onClose) onClose();
    } else {
      if (onOpen) onOpen();
    }
  };

  const handleAddOption = () => {
    setIsAddingOption(true);
  };

  const handleSaveNewOption = async () => {
    if (newOptionText.trim() !== '') {
      try {
        const optionData = {
          text: newOptionText,
          listType: listType,
          farmerId: farmerId, // Додано farmerId
        };
        if (showValue && newOptionValue.trim() !== '') {
          optionData.value = Number(newOptionValue);
        }
        const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/options', optionData);
        setOptions([...options, response.data]);
        console.log('Added new option:', response.data);
        setNewOptionText('');
        setNewOptionValue('');
        setIsAddingOption(false);
      } catch (error) {
        console.error('Помилка додавання опції:', error.response ? error.response.data : error.message);
      }
    } else {
      alert('Поле тексту опції не може бути порожнім.');
    }
  };

  const handleEditOption = (index) => {
    setIsEditingOptionIndex(index);
    setEditOptionText(options[index].text);
    setEditOptionValue(options[index].value || '');
  };

  const handleSaveEditOption = async () => {
    const optionData = {
      text: editOptionText,
    };
    if (showValue && editOptionValue !== '') {
      optionData.value = Number(editOptionValue);
    } else if (!showValue) {
      optionData.value = undefined;
    }

    try {
      const response = await axios.patch(
        `https://spptesti-2e99c730e09e.herokuapp.com/api/options/${options[isEditingOptionIndex]._id}`,
        optionData
      );

      const updatedOptions = [...options];
      updatedOptions[isEditingOptionIndex] = response.data;
      setOptions(updatedOptions);
      console.log('Edited option:', response.data);
      setIsEditingOptionIndex(null);
      setEditOptionText('');
      setEditOptionValue('');
    } catch (error) {
      console.error('Помилка редагування опції:', error.response ? error.response.data : error.message);
    }
  };

  const handleDeleteOption = async (index) => {
    const optionToDelete = options[index];
    try {
      await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/options/${optionToDelete._id}`);
      const updatedOptions = options.filter((_, i) => i !== index);
      setOptions(updatedOptions);
      console.log(`Deleted option with id: ${optionToDelete._id}`);
    } catch (error) {
      console.error('Помилка видалення опції:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="dropoptions">
      <label className="dropoptions__label">{label}</label>
      <div
        className={`dropoptions__selected ${isOpen ? 'open' : ''}`}
        onClick={handleToggle}
      >
        {selected || placeholder}
      </div>
      {isOpen && (
        <div className="dropoptions__options">
          {options.map((option, index) => (
            <div key={option._id} className="dropoptions__option">
              {isEditingOptionIndex === index ? (
                <div className="dropoptions__edit">
                  <input
                    type="text"
                    value={editOptionText}
                    onChange={(e) => setEditOptionText(e.target.value)}
                    className="dropoptions__input"
                    placeholder="Option text"
                  />
                  {showValue && (
                    <input
                      type="number"
                      value={editOptionValue}
                      onChange={(e) => setEditOptionValue(e.target.value)}
                      className="dropoptions__input"
                      placeholder="Option value"
                    />
                  )}
                  <button
                    className="dropoptions__icon-button"
                    onClick={handleSaveEditOption}
                  >
                    <i className="material-icons">save</i>
                  </button>
                </div>
              ) : (
                <div
                  className="dropoptions__option-content"
                  onClick={() => handleOptionClick(option)}
                >
                  <span>
                    {option.text}
                    {showValue && option.value != null ? ` - ${option.value}` : ''}
                  </span>
                  {(userRole === 'Admin' || userRole === 'Farm' || userRole === 'Ppc') && (
                    <div className="dropoptions__option-actions">
                      <button
                        className="dropoptions__icon-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditOption(index);
                        }}
                        title="Редагувати опцію"
                      >
                        <i className="material-icons">edit</i>
                      </button>
                      <button
                        className="dropoptions__icon-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteOption(index);
                        }}
                        title="Видалити опцію"
                      >
                        <i className="material-icons">delete</i>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
          {isAddingOption ? (
            <div className="dropoptions__add">
              <input
                type="text"
                value={newOptionText}
                onChange={(e) => setNewOptionText(e.target.value)}
                className="dropoptions__input"
                placeholder="Option text"
              />
              {showValue && (
                <input
                  type="number"
                  value={newOptionValue}
                  onChange={(e) => setNewOptionValue(e.target.value)}
                  className="dropoptions__input"
                  placeholder="Option value"
                />
              )}
              <button
                className="dropoptions__add-button"
                onClick={handleSaveNewOption}
                title="Зберегти нову опцію"
              >
                <i className="material-icons">save</i>
              </button>
            </div>
          ) : (
            (userRole === 'Admin' || userRole === 'Farm' || userRole === 'Ppc') && (
              <div
                className="dropoptions__add-option"
                onClick={handleAddOption}
                title="Додати нову опцію"
              >
                <button className="dropoptions__add-button">
                  <i className="material-icons">add</i>
                </button>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default DropOptions;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './Navbar.scss';
import hacker from './Navbar/hacker.png';
import hat from './Navbar/hat.png';
import login from './Navbar/log-in.png';
import logouto from './Navbar/door.png';
const Navbar = () => {
    const { logout, isLogin } = useContext(AuthContext);
    
  
    return (
        <nav>
            <div className="nav-header">
                <div className='logo-position'>
                    <img src={hat} alt="logo" className='nav-header-hat' />
                    <a href="/" className="logo-text">PPC TEAM</a>
                </div>
                
                {
                    isLogin
                    ? 
                    <div className='nav-roles'>
                        
                     
                        <ul id='nav-mobile'>
                            <li>
                                <a href="/" onClick={logout}>
                                    <img src={logouto} alt="" className='login-img'/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    : 
                    <ul id='nav-mobile'>
                        <li>
                            <Link to="/login">
                                <img src={login} alt="" className='login-img-opacity'/>
                            </Link>
                        </li>
                    </ul>
                }
            </div>
        </nav>
    );
}


export default Navbar;
